import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Footer from "../components/footer";
import Metatags from "../components/metatags";
import Tile from "../components/personal-dashboard/tile";
import Icon from "../components/personal-dashboard/icon";
import Primary from "../components/personal-dashboard/primary";
import Secondary from "../components/personal-dashboard/secondary";
import Cal from "../components/personal-dashboard/cal";
import CalStyles from "../components/personal-dashboard/calendar.module.scss";
import DayLine from "../components/personal-dashboard/dayline";
import List from "../components/personal-dashboard/list";
import ListItem from "../components/personal-dashboard/listItem";
import DashboardStyles from "../components/personal-dashboard/dashboard.module.scss";
import TileStyles from "../components/personal-dashboard/tile.module.scss";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">







    <Metatags title="Personal dashboard | May 10 | CSS Grid a day" description="An example of using CSS grid every day, from May 1–31st, 2019." thumbnail="https://cssgrid31.brett.cool/opengraph/may-10.png" url="https://cssgrid31.brett.cool" pathname="/may-10" mdxType="Metatags" />
    <div className={DashboardStyles.canvas}>
  <div className={DashboardStyles.body}>
    <Tile type="primary" mdxType="Tile">
      <div className={CalStyles.combo}>
        <DayLine mdxType="DayLine" />
        <Cal mdxType="Cal" />
      </div>
    </Tile>
    <Tile colour="red" mdxType="Tile">
      <Icon filename="heart" description="Heart rate" mdxType="Icon" />
      <div>
        <Primary mdxType="Primary">58 <span className="sc">bpm</span></Primary>
        <Secondary mdxType="Secondary">5 minutes ago</Secondary>
      </div>
    </Tile>
    <Tile colour="green" mdxType="Tile">
      <Icon filename="steps" description="Steps" mdxType="Icon" />
      <div>
        <Primary mdxType="Primary">3,271</Primary>
        <Secondary mdxType="Secondary">steps today</Secondary>
      </div>
    </Tile>
    <Tile colour="darkblue" mdxType="Tile">
      <Icon filename="sleep" description="Sleep" mdxType="Icon" />
      <div>
        <Primary mdxType="Primary">7.3h</Primary>
        <Secondary mdxType="Secondary">sleep last night</Secondary>
      </div>
    </Tile>
    <Tile colour="orange" mdxType="Tile">
      <Icon filename="computer" description="Computer" mdxType="Icon" />
      <div>
        <Primary mdxType="Primary">1.4h</Primary>
        <Secondary mdxType="Secondary">hours on computer</Secondary>
      </div>
    </Tile>
    <Tile type="list" mdxType="Tile">
      <Icon filename="todo" description="To-do" mdxType="Icon" />
      <List mdxType="List">
        <ListItem type="todo" list="Home" complete={true} mdxType="ListItem">Empty bins</ListItem>
        <ListItem type="todo" list="Home" complete={false} mdxType="ListItem">Pay rent</ListItem>
        <ListItem type="todo" list="Work" complete={false} mdxType="ListItem">Do things</ListItem>
      </List>
    </Tile>
    <Tile type="list" mdxType="Tile">
      <Icon filename="email" description="E-mail" mdxType="Icon" />
      <List type="email" mdxType="List">
        <ListItem unread={true} type="email" from="hello@brettjones.me" date="10 May 2019" mdxType="ListItem">That thing</ListItem>
        <ListItem type="email" from="coffee@place.co" date="9 May 2019" mdxType="ListItem">Your order has shipped</ListItem>
        <ListItem type="email" from="foo@bar.com" date="10 May 2019" mdxType="ListItem">Hello world</ListItem>
      </List>
    </Tile>
    <Tile type="empty" mdxType="Tile">
      <Icon filename="places" description="Places" mdxType="Icon" />
      <div className={TileStyles.emptystate}>
        <h3>No places visited today</h3>
      </div>
    </Tile>
    <Tile type="list" mdxType="Tile">
      <Icon filename="notifications" description="Notifications" mdxType="Icon" />
      <List type="notifications" mdxType="List">
        <ListItem unread={true} mdxType="ListItem">A notification has occured</ListItem>
        <ListItem mdxType="ListItem">Another thing happened</ListItem>
        <ListItem mdxType="ListItem">And a third thing happened</ListItem>
      </List>
    </Tile>
  </div>
    </div>
    <Footer date={10} prev={true} next={true} mdxType="Footer">
      <p>{`I was feeling under the weather today and so nearly missed this entry.`}</p>
      <p>{`It’s a personal dashboard. Showing I basically went nowhere, and did nothing today. All in all, it was an excellent day!`}</p>
    </Footer>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      